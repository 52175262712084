import React, { useEffect, useState } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import { Form, Input, Button, notification } from 'antd';
import { AuthWrapper } from './style';
import Heading from '../../../../components/heading/heading';
import logoBalken from '../../../../static/img/balken/balken_logo_BALKEN.png';
import { Api } from '../../../../config/api/axiosInterceptors';

function ResetPassword() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const email = queryParams.get('email');

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log('Token:', token);
    console.log('Email:', email);
  }, [token, email]);

  const handleSubmit = async (values) => {
    setLoading(true);
    const data = {
      token,
      email,
      ...values,
    };

    try {
      const response = await Api.post('/password/reset', data);
      notification.success({
        message: 'Contraseña actualizada',
        description: 'Tu contraseña ha sido actualizada exitosamente.',
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Hubo un problema al actualizar la contraseña. Por favor, intenta de nuevo.',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthWrapper>
      <div className="auth-contents">
        <Form name="resetPass" onFinish={handleSubmit} layout="vertical">
          <img className="balken-logo" src={logoBalken} alt="" />
          <Heading as="h3">
            <strong className="heading-title">Recuperar contraseña</strong>
            <p className="secondary-text">Ingresa tu nueva contraseña</p>
          </Heading>
          <Form.Item
            name="password"
            rules={[{ message: 'Por favor introduzca su contraseña!', required: true }]}
            initialValue=""
            label="Contraseña"
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="password_confirmation"
            rules={[{ message: 'Por favor introduzca la nueva contraseña!', required: true }]}
            initialValue=""
            label="Confirmar contraseña"
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button block htmlType="submit" type="primary" size="large" loading={loading}>
              Actualizar contraseña
            </Button>
          </Form.Item>
          <p className="return-text">
            Regresar a <NavLink to="/login">Iniciar sesión</NavLink>
          </p>
        </Form>
      </div>
    </AuthWrapper>
  );
}

export default ResetPassword;
