export const capitalizeString = (string) => {
  if (typeof string !== 'string' || string.length === 0) {
    return '';
  }
  // Reemplaza los guiones bajos por espacios
  const stringWithSpaces = string.replace(/_/g, ' ');
  return stringWithSpaces.charAt(0).toUpperCase() + stringWithSpaces.slice(1);
};

export const formatDateTime = (dateString) => {
  if (!dateString) return '';

  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];

  // Ajustar la fecha usando UTC para evitar problemas de zona horaria
  const date = new Date(dateString);
  const utcDate = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
  );

  const day = utcDate.getDate();
  const month = months[utcDate.getMonth()];
  const year = utcDate.getFullYear();
  // const hours = utcDate.getHours().toString().padStart(2, '0');
  // const minutes = utcDate.getMinutes().toString().padStart(2, '0');

  const formattedDate = `${day}/${month}/${year}`;
  // const formattedTime = `${hours}:${minutes} hrs`;
  // return dateString.includes('T') ? `${formattedDate} ${formattedTime}` : formattedDate;
  return formattedDate;
};

export const getLabelByValue = (options, value) => {
  const option = options.find((option) => option.value === value);
  return option ? option.label : null;
};

export const getNameById = (array, id) => {
  const item = array.find((element) => element.id === id);
  // console.log(item.name)
  return item ? item.name : null;
};

export const removeNullFields = (obj) => {
  // const filteredData = Object.keys(array).reduce((acc, key) => {
  //   if (array[key] !== null || array[key] !== undefined) {
  //     acc[key] = array[key];
  //   }
  //   return acc;
  // }, {});

  // return filteredData;
  // Use Object.entries to get an array of [key, value] pairs
  const entries = Object.entries(obj);
  // Filter out entries with values that are null or undefined
  const filteredEntries = entries.filter(([key, value]) => value !== null && value !== undefined && value !== '');

  // Reconstruct the object from the filtered entries
  const cleanedObj = Object.fromEntries(filteredEntries);

  return cleanedObj;
};

export const formatDate = (date) => {
  if (!date) return '';
  // Dividir la fecha en partes [dd, mm, aaaa]
  const [day, month, year] = date.split('-');
  // Formatear la fecha en formato aaaa-mm-dd
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const formatDateDatepicker = (date) => {
  return date.format('YYYY-MM-DD');
};

export const convertFormToFormData = (obj_) => {
  const obj = removeNullFields(obj_);
  const formData = new FormData();

  Object.entries(obj).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item, index) => {
        if (item?.originFileObj) {
          // Es un archivo, agregarlo como tal
          // formData.append(`${key}[${index}]`, item.originFileObj);
          formData.append(`${key}`, item.originFileObj);
        } else {
          // Es un array normal, agregar cada elemento
          formData.append(`${key}[${index}]`, item);
        }
      });
    } else if (value?.originFileObj) {
      // Es un archivo único, agregarlo como tal
      console.log('formda');
      formData.append(key, value.originFileObj);
    } else {
      // No es un archivo ni un array, agregarlo directamente
      formData.append(key, value);
    }
  });

  return formData;
};

export const objectToString = (obj) => {
  try {
    return JSON.stringify(obj);
  } catch (error) {
    console.error('Error al convertir el objeto en cadena de texto:', error);
    return null;
  }
};

export const stringToObject = (str) => {
  try {
    return JSON.parse(str);
  } catch (error) {
    console.error('Error al convertir la cadena de texto en objeto:', error);
    return null;
  }
};

export const getTagColor = (date, typeProspect) => {
  // method to manage the traffic light of prospects
  if (date == null || typeProspect === 'cliente') {
    return '';
  }
  const currentDate = new Date();
  const trafficLightDate = new Date(date);
  const timeDifference = currentDate - trafficLightDate;
  //the calculation in milliseconds
  const dayDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  if (dayDifference <= 4) {
    return 'green';
  } else if (dayDifference <= 7) {
    return 'yellow';
  } else {
    return 'red';
  }
};

export const handleFileAction = (fileURL, fileName, action = 'download') => {
  if (fileURL) {
    if (action === 'open') {
      const newWindow = window.open(fileURL, '_blank');
      // Verifica si la nueva ventana se abrió correctamente
      if (newWindow) {
        newWindow.focus(); // Lleva la nueva ventana al frente
      } else {
        console.error('No se pudo abrir una nueva ventana.');
      }
    } else {
      const link = document.createElement('a');
      link.href = fileURL;
      link.setAttribute('download', fileName); // Nombre del archivo para la descarga
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }
};

import numeral from 'numeral';
export const formatNumberWithCommas = (number) => {
  return numeral(number).format('0,0.00');
};

export const formatProspectStatus = (status) => {
  const statusMap = {
    prospecto: 'Prospecto Nuevo',
    propiedad_asignada: 'Prospecto con Propiedad Asignada',
    seguimiento_de_venta: 'Prospecto en Seguimientos',
    documentacion: 'Prospecto en Documentación',
    finalizado: 'Contrato Firmado',
  };

  if (Array.isArray(status)) {
    return status.map((s) => statusMap[s?.toLowerCase()] || s); // Mapea y devuelve el texto correspondiente
  }

  // Si es un string único
  return statusMap[status?.toLowerCase()] || status;
};

export const endpointError = (errorResponse) => {
  // Extrae y formatea los errores específicos
  let errorDetails = '';
  if (errorResponse.errors) {
    errorDetails = Object.keys(errorResponse.errors)
      .map((key) => `${key}: ${errorResponse.errors[key].join(', ')}`)
      .join(' | ');
  }

  return errorDetails;
};

export const createDashboardQueryParams = ({ project_id, start_date, end_date }) => {
  const params = new URLSearchParams();

  if (project_id) params.append('project_id', project_id);
  if (start_date) params.append('start_date', start_date);
  if (end_date) params.append('end_date', end_date);

  return params.toString();
};
