import React, { useState } from 'react';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import { Form, Input, Button, notification } from 'antd';
import { AuthWrapper } from './style';
import Heading from '../../../../components/heading/heading';
import logoBalken from '../../../../static/img/balken/balken_logo_BALKEN.png';
import { Api } from '../../../../config/api/axiosInterceptors';

function ForgotPassword() {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const response = await Api.post('password/email', values);
      notification.success({
        message: 'Correo enviado',
        description: 'Se ha enviado un correo para restablecer la contraseña.',
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Hubo un problema al enviar el correo. Por favor, intenta de nuevo.',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthWrapper>
      <div className="auth-contents">
        <Form name="forgotPass" onFinish={handleSubmit} layout="vertical">
          <img className="balken-logo" src={logoBalken} alt="" />
          <Heading as="h3">
            <strong className="heading-title">Recuperar contraseña</strong>
            <p className="secondary-text">
              Si olvidaste tu contraseña puedes recuperarla con ayuda de tu correo electrónico, ahí te enviaremos las
              instrucciones que necesitas.
            </p>
          </Heading>
          <Form.Item
            label="Correo electrónico"
            name="email"
            rules={[{ required: true, message: 'Por favor ingresa tu correo', type: 'email' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary" size="large" block loading={loading}>
              Recuperar contraseña
            </Button>
          </Form.Item>
          <p className="return-text">
            Regresar a <NavLink to="/login">Iniciar sesión</NavLink>
          </p>
        </Form>
      </div>
    </AuthWrapper>
  );
}

export default ForgotPassword;
