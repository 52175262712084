import { Col, DatePicker, Row, Select, Spin, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import HeaderCard from '../../components/headerCard/headerCard';
import { getAllPaginatedData } from '../../config/api/methodsByApi';
import useRole from '../../hooks/useRole';
import { Main } from '../styled';
import CardGrid from './overview/CardGrid';
import PaymentStatus from './overview/PaymentStatus';
import PaymentsByProject from './overview/PaymentsByProject';
import ProspectsByStage from './overview/ProspectsByStage';
import ProspectsWithoutProgressList from './overview/ProspectsWithoutProgressList';
import SalesStatus from './overview/SalesStatus';
import Sellers from './overview/Sellers';

const { TabPane } = Tabs;
const { Option } = Select;

function Dashboard() {
  const role = useRole();
  const isAdmin = role === 'administrador';
  const formatDate = 'DD/MM/YYYY';

  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    setLoading(true);
    getAllPaginatedData('/projects', 'projects', setProjects);
    setLoading(false);
  }, []);

  const handleStartDateChange = (date, dateString) => {
    setStartDate(dateString);
  };

  const handleEndDateChange = (date, dateString) => {
    setEndDate(dateString);
  };

  const handleSelectChange = (value) => {
    setSelectedProject(value || ''); // Actualizar el valor seleccionado
  };

  return (
    <>
      <HeaderCard
        title="Inicio"
        extraElements={[
          // <SearchInput onChange={handleSearchChange} />,
          <Select
            placeholder="Selecciona un proyecto"
            onChange={handleSelectChange}
            allowClear
            className="page-header-select-filter"
          >
            {projects.map((type) => (
              <Option key={type.id} value={type.id}>
                {type.name}
              </Option>
            ))}
          </Select>,
          <DatePicker format={formatDate} placeholder="Fecha inicial" onChange={handleStartDateChange} />,
          <DatePicker format={formatDate} placeholder="Fecha final" onChange={handleEndDateChange} />,
        ]}
      />
      <Main>
        <Spin spinning={loading}>
          <Tabs defaultActiveKey={isAdmin ? '1' : '2'} className="custom-tabs">
            {isAdmin && (
              <TabPane tab="Contratos" key="1">
                <Row gutter={25}>
                  <Col xxl={24} md={24} sm={24}>
                    <Row gutter={25}>
                      <CardGrid
                        isAdmin={isAdmin}
                        xxl={8}
                        lg={8}
                        md={12}
                        xs={24}
                        type="contratos"
                        project_id={selectedProject}
                        start_date={startDate}
                        end_date={endDate}
                      />
                    </Row>
                  </Col>

                  <Col xxl={12} lg={12} md={12} xs={24}>
                    <PaymentStatus project_id={selectedProject} start_date={startDate} end_date={endDate} />
                  </Col>

                  <Col xxl={12} lg={12} md={12} xs={24}>
                    <PaymentsByProject project_id={selectedProject} start_date={startDate} end_date={endDate} />
                  </Col>
                </Row>
              </TabPane>
            )}

            <TabPane tab="Ventas" key="2">
              <Row gutter={25}>
                <Col xxl={24} md={24} sm={24} xs={24}>
                  <Row gutter={25}>
                    <CardGrid
                      isAdmin={isAdmin}
                      lg={6}
                      md={12}
                      xs={24}
                      project_id={selectedProject}
                      start_date={startDate}
                      end_date={endDate}
                    />
                  </Row>
                </Col>

                <Col xxl={12} lg={12} md={12} xs={24}>
                  <SalesStatus project_id={selectedProject} start_date={startDate} end_date={endDate} />
                </Col>

                <Col xxl={12} lg={12} md={12} xs={24}>
                  <ProspectsByStage project_id={selectedProject} start_date={startDate} end_date={endDate} />
                </Col>

                <Col xxl={24} lg={24} xs={24}>
                  <Sellers />
                </Col>

                <Col xxl={24} lg={24} xs={24}>
                  <ProspectsWithoutProgressList
                    project_id={selectedProject}
                    start_date={startDate}
                    end_date={endDate}
                  />
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </Spin>
      </Main>
    </>
  );
}

export default Dashboard;
