export const roleOptions = [
  { value: 'administrador', label: 'Administrador' },
  { value: 'cliente', label: 'Cliente' },
  { value: 'vendedor', label: 'Vendedor' },
  { value: 'gerente_comercial', label: 'Gerente comercial' },
];
export const roleManagerOptions = [
  { value: 'cliente', label: 'Cliente' },
  { value: 'vendedor', label: 'Vendedor' },
];

export const typeOptionsInCatalogs = [
  { value: 'estate', label: 'Inmueble' },
  { value: 'payment_method', label: 'Método de pago' },
];

export const typeRoles = {
  administrador: '/administrador',
  cliente: '/cliente',
  vendedor: '/vendedor',
};

export const typePaymentStatus = [
  { value: 'pagado', label: 'Pagado' },
  { value: 'no_pagado', label: 'No pagado' },
  { value: 'vencido', label: 'Vencido' },
];

export const statusByProspect = [
  { value: 'prospecto', label: 'Prospecto' },
  { value: 'propiedad_asignada', label: 'Propiedad asignada' },
  { value: 'seguimiento_de_venta', label: 'Seguimiento de venta' },
  { value: 'documentacion', label: 'Documentación' },
  { value: 'finalizado', label: 'Finalizado' },
];

export const typesByProspect = [
  { value: 'prospecto', label: 'Prospecto' },
  { value: 'cliente', label: 'Cliente' },
];

export const situationSalesTrackings = [
  { value: 'llamada_telefonica', label: 'Llamada telefónica' },
  { value: 'pre_entrevista', label: 'Pre-entrevista' },
  { value: 'post_entrevista', label: 'Post- entrevista' },
  { value: 'en_calificacion', label: 'En calificación' },
  { value: 'ganado', label: 'Ganado' },
  { value: 'perdido', label: 'Perdido' },
];

export const statusByTicket = [
  { value: 'abierto', label: 'Abierto' },
  { value: 'en_revision', label: 'En revisión' },
  { value: 'cerrado', label: 'Cerrado' },
];

export const validatePaymentStatus = [
  { value: '1', label: 'Conciliado' },
  { value: '0', label: 'No conciliado' },
];
// Items from Sidebar
// import userIcon from '../static/img/balken/usuarios_BALKEN.png';
import cataloguesIcon from '../static/img/balken/catalogo_BALKEN.svg';
import paymentsIcon from '../static/img/balken/pagos_BALKEN.svg';
import prospectiveIcon from '../static/img/balken/prospectos_BALKEN.svg';
import projectsIcon from '../static/img/balken/proyectos_BALKEN.svg';
import usersIcon from '../static/img/balken/usuarios_BALKEN.svg';
import expedienteIcon from '../static/img/balken/expedientes_BALKEN.svg';
import estatesIcon from '../static/img/balken/inmuebles_BALKEN.svg';
import homeIcon from '../static/img/balken/inicio_BALKEN.svg';
import ticketIcon from '../static/img/balken/aclaraciones_BALKEN.svg';

export const sidebarItems = [
  {
    key: 'dashboard-admin',
    image: homeIcon,
    title: 'Inicio',
    roles: ['administrador', 'gerente_comercial'],
    path: '/dashboard',
  },
  {
    key: 'catalogues',
    image: cataloguesIcon,
    title: 'Catálogos de sistema',
    roles: ['administrador'],
    path: '/catálogos',
  },
  {
    key: 'customerInfo',
    image: expedienteIcon,
    title: 'Expediente',
    roles: ['cliente'],
    path: '/expediente',
  },
  {
    key: 'user-management',
    image: usersIcon,
    title: 'Gestión de usuarios',
    roles: ['administrador', 'gerente_comercial'],
    path: '/gestion-usuarios',
  },
  {
    key: 'projects',
    image: projectsIcon,
    title: 'Proyectos',
    roles: ['administrador'],
    path: '/proyectos',
  },
  {
    key: 'estates',
    image: estatesIcon,
    title: 'Inmuebles',
    roles: ['administrador'],
    path: '/inmuebles',
  },
  {
    key: 'payments',
    image: paymentsIcon,
    title: 'Pagos',
    roles: ['administrador', 'cliente'],
    path: '/pagos',
  },
  {
    key: 'prospective_clients',
    image: prospectiveIcon,
    title: 'Expedientes',
    roles: ['administrador'],
    path: '/prospectos',
  },
  {
    key: 'prospective_clients',
    image: prospectiveIcon,
    title: 'Prospección',
    roles: ['vendedor', 'gerente_comercial'],
    items: [
      { key: 'prospects-list', path: '/prospectos', label: 'Clientes prospectos' },
      { key: 'prospects-create', path: '/seguimiento-venta', label: 'Seguimiento de venta' },
    ],
  },
  {
    key: 'tickets',
    image: ticketIcon,
    title: 'Mesa de ayuda',
    roles: ['administrador', 'cliente'],
    path: '/tickets',
    // items: [
    //   { key: 'prospects-list', path: '/prospectos', label: 'Clientes prospectos' },
    //   { key: 'prospects-create', path: '/seguimiento-venta', label: 'Seguimiento de venta' },
    // ],
  },
];
