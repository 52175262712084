import { Col, Dropdown, Menu, Row } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { CardToolbox } from '../../container/styled';
import plus from '../../static/img/balken/agregar_BALKEN.png';
import { Button } from '../buttons/buttons';
import { PageHeader } from '../page-headers/page-headers';
// import arrow from '../../static/img/balken/regresar_BALKEN.png';
import { DownOutlined } from '@ant-design/icons';
import arrow from '../../static/img/balken/regresar_BALKEN_1.svg';

const ICONS = {
  arrow,
  plus,
};

function HeaderCard({
  title,
  buttonLabel,
  buttonLink,
  buttonIcon,
  buttonType = 'primary',
  buttonSize = 'large',
  ghost = true,
  extraElements = [],
}) {
  const iconSrc = ICONS[buttonIcon] || null;
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleMenuClick = (e) => {
    e.domEvent.stopPropagation();
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {extraElements.map((element, index) => (
        <Menu.Item key={index}>{element}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <CardToolbox>
      <PageHeader
        ghost={ghost}
        title={title}
        buttons={[
          <Row gutter={[25, 25]} key="buttons-row">
            {extraElements.length > 2 ? (
              <Col>
                <Dropdown
                  overlay={menu}
                  trigger={['click']}
                  onOpenChange={(open) => setDropdownOpen(open)}
                  open={dropdownOpen}
                >
                  <Button type="dark" outlined size="large" onClick={(e) => e.preventDefault()}>
                    Mostrar filtros <DownOutlined />
                  </Button>
                </Dropdown>
              </Col>
            ) : (
              extraElements.map((element, index) => <Col key={index}>{element}</Col>)
            )}
            {buttonLabel && (
              <Col key="button-col">
                <Link to={buttonLink}>
                  <Button type={buttonType} size={buttonSize}>
                    {iconSrc && <img src={iconSrc} alt={buttonIcon} />}
                    {buttonLabel}
                  </Button>
                </Link>
              </Col>
            )}
          </Row>,
        ]}
      />
    </CardToolbox>
  );
}

export default HeaderCard;
