import { Empty, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { ChartjsBarChart } from '../../../components/charts/chartjs';
import { Api } from '../../../config/api/axiosInterceptors';
import { CardBarChart } from '../style';
import { createDashboardQueryParams } from '../../../utils/utils';

function PaymentsByProject({ project_id, start_date, end_date }) {
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [loading, setLoading] = useState(false);

  const getProjects = async () => {
    setLoading(true);
    try {
      const queryParams = createDashboardQueryParams({ project_id, start_date, end_date });
      const response = await Api.get(`/dashboard/balance_by_project?${queryParams}`);
      const { projects } = response?.data?.response;
      setChartData(mapProjectsToChartData(projects));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const mapProjectsToChartData = (projects) => {
    const labels = projects.map((proj) => proj.project);
    const data = projects.map((proj) => parseFloat(proj.amount));

    return {
      labels,
      datasets: [
        {
          data,
          backgroundColor: '#F8D247',
          barPercentage: 0.6,
          borderRadius: 10,
        },
      ],
    };
  };

  useEffect(() => {
    getProjects();
  }, [project_id, start_date, end_date]);

  return (
    <>
      <Cards title="PAGOS POR PROYECTO" size="large">
        {loading ? (
          <Skeleton active />
        ) : chartData.datasets?.length > 0 && chartData.datasets[0].data.length > 0 ? (
          <ChartjsBarChart datasets={chartData.datasets} labels={chartData.labels} height={180} />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Cards>
    </>
  );
}

export default PaymentsByProject;
