import { Badge, Empty, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { ChartjsDonut } from '../../../components/charts/chartjs';
import { Api } from '../../../config/api/axiosInterceptors';
import { BottomChartData, Wrapper } from '../style';
import { createDashboardQueryParams } from '../../../utils/utils';

function PaymentStatus({ project_id, start_date, end_date }) {
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [loading, setLoading] = useState(false);

  const getPaymentStatus = async () => {
    setLoading(true);
    try {
      const queryParams = createDashboardQueryParams({ project_id, start_date, end_date });
      const response = await Api.get(`/dashboard/balance?${queryParams}`);

      const { no_pagado, pagado, vencido } = response?.data?.response;
      const total = parseFloat(no_pagado) + parseFloat(pagado) + parseFloat(vencido);

      // Si el total es 0, asignamos 0 a todos los porcentajes
      let porcentajePagado = null;
      let percentagePorPagar = null;
      let porcentajeVencido = null;

      if (total > 0) {
        porcentajePagado = ((parseFloat(pagado) / total) * 100).toFixed(2);
        percentagePorPagar = ((parseFloat(no_pagado) / total) * 100).toFixed(2);
        porcentajeVencido = ((parseFloat(vencido) / total) * 100).toFixed(2);
      }

      const data = total > 0 ? [porcentajePagado, percentagePorPagar, porcentajeVencido] : [];
      // const labels = typePaymentStatus.map((status) => status.label);
      const labels = ['Pagado', 'Por pagar', 'Vencido'];
      const datasets = [{ data, backgroundColor: ['#F8D247', '#F8A647', '#F66B20'] }];
      setChartData({ labels, datasets });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPaymentStatus();
  }, [project_id, start_date, end_date]);

  return (
    <Wrapper>
      <Cards title="ESTATUS DE PAGOS" size="large">
        {loading ? (
          <Skeleton active />
        ) : chartData.datasets?.length > 0 && chartData.datasets[0].data.length > 0 ? (
          <div className="chart">
            <ChartjsDonut datasets={chartData.datasets} labels={chartData.labels} />
            <BottomChartData className="d-flex justify-content-center">
              <div className="data-center">
                <div className="chart-label">
                  <Badge color="#F8D247" />
                  <span className="chart-text">{chartData.labels[0]}</span>
                </div>
                <sub className="percent">{chartData.datasets[0]?.data[0]}%</sub>
              </div>
              <div className="data-center">
                <div className="chart-label">
                  <Badge color="orange" />
                  <span className="chart-text">{chartData.labels[1]}</span>
                </div>
                <sub className="percent">{chartData.datasets[0]?.data[1]}%</sub>
              </div>
              <div className="data-center">
                <div className="chart-label">
                  <Badge color="volcano" />
                  <span className="chart-text">{chartData.labels[2]}</span>
                </div>
                <sub className="percent">{chartData.datasets[0]?.data[2]}%</sub>
              </div>
            </BottomChartData>
          </div>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Cards>
    </Wrapper>
  );
}

export default PaymentStatus;
