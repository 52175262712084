import { Col, Popover, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { OverviewSalesCard } from '../style';
import { Api } from '../../../config/api/axiosInterceptors';
import ArrowIcon from '../../../static/img/balken/morosidad_BALKEN.svg';
import RemoteIcon from '../../../static/img/balken/p-apartados_BALKEN.svg';
import ContractualIcon from '../../../static/img/balken/p-contractuales_BALKEN.svg';
import NoProgressIcon from '../../../static/img/balken/p-sin-avances_BALKEN.svg';
import ClockIcon from '../../../static/img/balken/por-cobrar_BALKEN.svg';
import DollarIcon from '../../../static/img/balken/total-ingresos_BALKEN.svg';
import ProspectsIcon from '../../../static/img/balken/total_prospectos_BALKEN.svg';
import { createDashboardQueryParams, formatNumberWithCommas } from '../../../utils/utils';

function CardGrid({ isAdmin, xxl, lg, md, xs, type, project_id, start_date, end_date }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getCardData = async () => {
    setLoading(true);
    try {
      const queryParams = createDashboardQueryParams({ project_id, start_date, end_date });
      let responseBalance;
      if (isAdmin) {
        responseBalance = await Api.get(`/dashboard/balance?${queryParams}`);
      }

      const responseProspects = await Api.get(`/dashboard/prospects-data?${queryParams}`);
      const { total, in_documentation, without_following, on_hold } = responseProspects?.data?.prospects;

      const mappedData =
        responseBalance && type === 'contratos'
          ? [
              {
                title: 'Ingresos totales',
                value: `$${formatNumberWithCommas(parseFloat(responseBalance.data.response.pagado).toFixed(2))}`,
                icon: DollarIcon,
                popover: 'Cantidad total de ingresos por los pagos de los clientes en el sistema',
              },
              {
                title: 'Pagos por cobrar',
                value: `$${formatNumberWithCommas(parseFloat(responseBalance.data.response.no_pagado).toFixed(2))}`,
                icon: ClockIcon,
                popover: 'Cantidad total de dinero pendiente de los clientes en el sistema',
              },
              {
                title: 'No pagado',
                value: `$${formatNumberWithCommas(parseFloat(responseBalance.data.response.vencido).toFixed(2))}`,
                icon: ArrowIcon,
                popover: 'Cantidad total de los pagos de los clientes que se les venció su fecha de pago',
              },
            ]
          : [
              {
                title: 'Total de prospectos',
                value: total,
                icon: ProspectsIcon,
                popover: 'Cantidad total de prospectos registrados en el sistema',
              },
              {
                title: 'Prospectos sin avances',
                value: without_following,
                icon: NoProgressIcon,
                popover:
                  'Cantidad total de los prospectos los cuales no tienen un seguimiento y se encuentran en semáforo rojo',
              },
              {
                title: 'Prospectos con apartado',
                value: on_hold,
                icon: RemoteIcon,
                popover: 'Cantidad total de prospectos con un apartado en su inmueble',
              },
              {
                title: 'Clientes',
                value: in_documentation,
                icon: ContractualIcon,
                popover: 'Cantidad total de clientes que compraron un inmueble y cerraron su venta',
              },
            ];

      setData(mappedData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCardData();
  }, [project_id, start_date, end_date]);

  return (
    <>
      {loading ? (
        <Cards headless>
          <Skeleton active />
        </Cards>
      ) : (
        data.map((card, index) => (
          <Col xxl={xxl} lg={lg} md={md} xs={xs} key={index}>
            <Popover
              content={card.popover}
              overlayStyle={{ maxWidth: 350 }} // Definiendo el ancho máximo
            >
              <Cards headless>
                <OverviewSalesCard>
                  <div className="icon-box box-primary">
                    <img src={card.icon} alt="" />
                  </div>
                  <div className="card-details">
                    <h2>{card.value}</h2>
                    <span>{card.title}</span>
                  </div>
                </OverviewSalesCard>
              </Cards>
            </Popover>
          </Col>
        ))
      )}
    </>
  );
}

export default CardGrid;
